import React from "react"
import Layout from "@components/layout"
import { GetPMLP } from "@components/getpowermetrics/get-pm-lp"

const EverybodysBusinessOne = () => {
  return (
    <Layout
      titleExtension="Klipfolio PowerMetrics"
      seo={{
        title: "Make Analytics Everybody’s Business",
        description: `PowerMetrics helps data teams unlock the value in their data stack and make working with metrics effortless for everyone. Provide a catalog of trusted metrics.`,
        tracking: ["noindex", "nofollow", "noarchive"],
      }}
      fullWidth
      marginless
      hideOverflow
      microsite
      hideNav
    >
      <GetPMLP lp />
    </Layout>
  )
}

export default EverybodysBusinessOne
